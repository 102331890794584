import { useEffect, useState } from "react";
import formatNumber from "@/Lib/Common";
import moment from "moment-timezone";
import { BidBadge } from "./BidBadge";
import { TbClockHour3 } from "react-icons/tb";

export function BidBadgeWithCountdown({ targetTime, bid, gunbrokerId, ...props }) {
    const [currentTime, setCurrentTime] = useState(moment.utc());
    const [timeLeft, setTimeLeft] = useState(moment.duration(targetTime.diff(moment.utc())));
    const [variant, setVariant] = useState();
    const [displayTime, setDisplayTime] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            const now = moment.utc();
            setCurrentTime(now);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const newTimeBetween = moment.duration(targetTime.diff(currentTime));
        setTimeLeft(newTimeBetween);

        let badgeVariant = 'default';
        if (newTimeBetween.asHours() < 24) {
            if (newTimeBetween.asHours() <= 0) {
                badgeVariant = 'lastcall';
            } else if (newTimeBetween.asHours() < 2) {
                badgeVariant = 'danger';
            } else {
                badgeVariant = 'warning';
            }
        }

        setVariant(badgeVariant);
    }, [currentTime, targetTime]);

    useEffect(() => {
        const time = timeLeft.asSeconds() >= 0
            ? (timeLeft.days() > 0
                ? `${timeLeft.days()} day${timeLeft.days() > 1 ? 's' : ''}`
                : `${formatTimePart(timeLeft.hours())}:${formatTimePart(timeLeft.minutes())}:${formatTimePart(timeLeft.seconds())}`)
            : "Last Call";
        setDisplayTime(time);

        if (time === "Last Call") {
            // Retrieve the current array from localStorage or initialize an empty array
            const currentIds = JSON.parse(localStorage.getItem("lastCallGunbrokerIds") || "[]");
            if (!currentIds.includes(gunbrokerId) && gunbrokerId !== null) {
                currentIds.push(gunbrokerId);
                localStorage.setItem("lastCallGunbrokerIds", JSON.stringify(currentIds));
            }
        }
    }, [timeLeft, gunbrokerId]);

    const formatTimePart = (value) => {
        return value.toString().padStart(2, '0');
    };

    return variant && <BidBadge variant={variant} {...props}>
        <span className="flex items-center">
            <TbClockHour3 className="inline w-[21px] h-[21px] me-1 text-white" />
            <span className="font-semibold tracking-[0.08px]">
                {displayTime}
            </span>
        </span>
        <span className="font-normal">Bid <span className="font-semibold tracking-[0.08px]">${formatNumber(bid)}</span></span>
    </BidBadge>;
}
